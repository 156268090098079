import * as React from 'react'
import { graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { MDXProvider } from "@mdx-js/react"
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import SSRProvider from 'react-bootstrap/SSRProvider'

import DefaultHeader from '../components/default-header'
import FinAidMenu from '../components/financialAidMenu'
import Footer from '../components/footer'
import GdprPanel from '../components/gdpr'
import Navigation from '../components/navigation'
import Seo from '../components/seo'
import SpeakWithAdmissions from '../components/speakWithAdmissions'
import NewsletterSignup from '../components/newsletterSignUp'

import '../styles/basic-content.scss'

export default function ContentLibraryContentPage({ data }) {

    const post = data.mdx
    const shortcodes = {
  
    }

    return (      
        <> 
            <SSRProvider>
                <Navigation />
                <DefaultHeader type="xl" headline={post.frontmatter.headline} heroBackground={post.frontmatter.heroBackground} heroMobileBackground={post.frontmatter.heroMobileBackground} subHeadline={post.frontmatter.subheadline} />
                <div id="body" className="basic">
                    <Container fluid="lg">
                        <Row className="justify-content-center py-4 my-4">
                            <Col xl={8} lg={9} md={10} xs={12}>
                                <MDXProvider components={shortcodes}>
                                    <MDXRenderer>{post.body}</MDXRenderer>
                                </MDXProvider>      
                            </Col>
                        </Row>
                    </Container>
                    <Container fluid="lg">
                        <div id="bottom-contact">
                            <Row className="py-4 py-xl-5 justify-content-center gx-5">
                                <Col md={6} className="mb-0 mb-md-4 mb-xl-0">
                                    <FinAidMenu type="bottom" />
                                </Col> 
                                <Col md={6} >
                                    <SpeakWithAdmissions menuType="bottom-green" className="fin-aid" />   
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </div>
                <Footer />
                <GdprPanel />
                <NewsletterSignup />
            </SSRProvider>
        </>
    )
}

export const Head = ({data}) => (
    <Seo 
        title={data.mdx.frontmatter.metadata.Title} 
        description={data.mdx.frontmatter.metadata.Description} 
        pathname={data.mdx.frontmatter.metadata.Link}
        ogImage={data.mdx.frontmatter.metadata.ogImage}
        cardImage={data.mdx.frontmatter.metadata.cardImage} 
    />
)

export const query = graphql`
    query($slug: String!) {
        mdx(slug: { eq: $slug }) {
            frontmatter {
                heroBackground
                subheadline
                headline
                title
                category
                dateCreated
                metadata {
                    Description
                    Keywords
                    Link
                    Title
                    ogImage
                    cardImage
                }
            }
            body
        }
    }   
`
